import { Banner, Button, Icon, Layout, LegacyCard, Page } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

import { List, links } from "./constants";
import { CSMBlock } from "./CSMBlock";
import { RSSBlock } from "./RSSBlock";
import { SupportFormContainer } from "../../_sharedComponents/SupportForm";

const linkSettings = { target: "_blank", rel: "noopenere noreferrer" };

const PageWrapper = styled.div`
  & .Polaris-Page--narrowWidth {
    max-width: 934px !important;
    padding: 0;

    & .Polaris-Layout__Section:not(:nth-child(1)) .Polaris-LegacyCard,
    & .Polaris-Layout__Section:not(:nth-child(1)) {
      max-width: 457px;
      min-width: 457px !important; //To override default Polaris styles
    }
    & .Polaris-Layout__Section:not(:nth-child(1)) {
      margin-left: 20px;
    }
    .Polaris-Page-Header__PrimaryActionWrapper a.Polaris-Button:hover {
      text-decoration: none;
    }
    & .external-btn {
      margin-top: 2rem;
    }
    & .external-btn a.Polaris-Button:hover {
      text-decoration: none;
    }
    & .faq-link {
      text-decoration: underline;
      line-height: 18px;
    }
    & .faq-link:hover {
      color: var(--p-color-text-interactive-hover);
    }
  }
`;
export function AdminSupportRoute(): JSX.Element {
  const [showBanner, setShowBanner] = React.useState(
    JSON.parse(localStorage.getItem("banner-is-open") || "{}") || false
  );

  const dismissBanner = () => {
    localStorage.setItem("banner-is-open", JSON.stringify(!showBanner));
    setShowBanner(!showBanner);
  };

  const rightBlockRef = useRef<HTMLDivElement>(null);

  const resizeHandler = useCallback(
    debounce(() => {
      const leftBlock = document.getElementsByClassName("Polaris-Layout__Section")[1]; // no way to use refs for Layout.Section
      const csmBlock = document.getElementsByClassName("Polaris-Layout__Section--secondary")[0].children[0]
        .clientHeight;
      const changeLogHeaderHeight = 145;
      const changeLogFooterHeight = 32;
      rightBlockRef.current!.style.maxHeight = `${
        leftBlock.getBoundingClientRect().height - csmBlock - changeLogHeaderHeight - changeLogFooterHeight
      }px`; // height of csmBlock - height of the changelog header (145px) - height of changelog footer (32px)
    }, 100),
    []
  );

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <PageWrapper>
        <Page narrowWidth title="Help">
          <Layout>
            <Layout.Section fullWidth>
              {!!showBanner && (
                <Banner
                  onDismiss={() => {
                    dismissBanner();
                  }}
                >
                  <p>
                    Need help? Think of this page as your Smartrr concierge. You&apos;ll find answers to common
                    questions here. If you don&apos;t, please reach out to us with your questions or feature
                    requests. Letting us know what&apos;s important to you doesn&apos;t just help improve our core
                    product—it helps us better serve other Shopify stores as well.
                  </p>
                </Banner>
              )}
            </Layout.Section>
            <Layout.Section>
              <LegacyCard sectioned title="Top 10 FAQs">
                <List>
                  {links.map(({ text, link }) => (
                    <li key={link}>
                      <a className="faq-link" href={link} {...linkSettings}>
                        {text}
                      </a>
                    </li>
                  ))}
                </List>
                <div className="external-btn">
                  <Button
                    icon={<Icon source={ExternalMinor} color="base" />}
                    url="https://help.smartrr.com/docs/"
                    external
                  >
                    All help docs
                  </Button>
                </div>
              </LegacyCard>
              <SupportFormContainer />
            </Layout.Section>
            <Layout.Section secondary>
              <CSMBlock />
              <RSSBlock rightBlockRef={rightBlockRef} />
            </Layout.Section>
          </Layout>
        </Page>
      </PageWrapper>
    </React.Fragment>
  );
}
