import { Button, LegacyCard, LegacyStack, Spinner } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { Annette, Carlos, Casey, Sam, placeholder } from "./assets";

const CsmCard = styled.div`
  margin-bottom: 20px;
  & .Polaris-LegacyCard {
    min-height: 159px;
  }
  & a:hover {
    text-decoration: none;
  }

  & .csm-card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    & a.Polaris-Button {
      font-weight: 500;
    }
    & img {
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }
  }
`;

export const CSMBlock = () => {
  const [csmEmail, setCsmEmail] = useState<string>("");
  const [csmFirst, setCsmFirst] = useState<string>("");
  const [csmLast, setCsmLast] = useState<string>("");
  const [hasCsm, setHasCsm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCSM();
  }, []);
  const getCSM = useCallback(async () => {
    setIsLoading(true);
    const res = await typedFrontendVendorApi.getReq(`/integrations/hubspot/owners`);
    if (res.type === "success") {
      setHasCsm(true);
      setCsmEmail(res.body.email);
      setCsmFirst(res.body.firstName);
      setCsmLast(res.body.lastName);
    }
    setIsLoading(false);
  }, []);
  //Need to come up with a better solution than this

  let csmImg;
  switch (csmFirst) {
    case "Casey": {
      csmImg = <img src={Casey.default} />;
      break;
    }
    case "Carlos": {
      csmImg = <img src={Carlos.default} />;
      break;
    }
    case "Samantha": {
      csmImg = <img src={Sam.default} />;
      break;
    }
    case "Annette": {
      csmImg = <img src={Annette.default} />;
      break;
    }
    default: {
      csmImg = <img src={placeholder.default} />;
      break;
    }
  }
  return (
    <CsmCard>
      {isLoading ? (
        <LegacyCard sectioned>
          <LegacyStack alignment="center" distribution="center">
            <Spinner />
          </LegacyStack>
        </LegacyCard>
      ) : (
        <LegacyCard
          sectioned
          title={hasCsm ? `${csmFirst} ${csmLast} is your CSM` : "Get a Client Success Manager"}
        >
          {hasCsm ? (
            <p>You have access to a dedicated Client Success Manager.</p>
          ) : (
            <p>Our Grow &amp; Excel plans get a dedicated CSM</p>
          )}
          <div className="csm-card-footer">
            {hasCsm ? (
              <a className="Polaris-Button Polaris-Button--primary" href={`mailto:${csmEmail}`}>
                {`💌 Email ${csmFirst}`}
              </a>
            ) : (
              <Button primary onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/smartrr-account`)}>
                💎 Upgrade to Excel
              </Button>
            )}
            {csmImg}
          </div>
        </LegacyCard>
      )}
    </CsmCard>
  );
};
