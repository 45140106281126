import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSupportRoute } from "@vendor-app/app/AdminRoute/AdminSupportRoute";

export const Route = createLazyFileRoute("/admin/support")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return <AdminSupportRoute />;
}
